<template>
  <div>
    <el-row class="default-table">
      <el-col :span="24">
        <div v-loading="loadingStatus"
             :element-loading-text="loadingText"
             element-loading-spinner="el-icon-loading">
          <el-table
              :data="dataList"
              :span-method="objectSpanMethod"
              :summary-method="getSummaries"
              show-summary
              :cell-class-name="cellClassName"
              highlight-current-row
              style="width: 100%;"
              border>
            <el-table-column
                prop="group_name"
                label="组别"
                align="center"
                width="150">
              <template slot-scope="{row}">
                {{ row.group_name }}
              </template>
            </el-table-column>
            <el-table-column
                prop="nickname"
                label="红人昵称"
                align="center"
                width="150">
              <template slot-scope="scope">
                <span :title='`${scope.row.nickname}`'>{{ scope.row.name }}
                  <span style="color: #ff5a91;margin-left: 5px"
                        v-if="scope.row.nickname&&scope.row.nickname!==scope.row.name">
                    {{ scope.row.nickname }}</span></span>
                <i class="el-icon-refresh" title="重新计算" style="cursor:pointer;margin-left: 4px;color: rgb(250, 152, 65)"
                   v-if="userPermissions.indexOf('pa_kpi_target__save') !== -1"  @click="clickRefresh(scope.row)"></i>
              </template>
            </el-table-column>
            <el-table-column
                prop="bef_two_quarter_profit"
                label="前两个季度的月均利润"
                align="center"
                width="200">
              <template slot-scope="{row,column}">
                <editable-cell :show-input="false"
                               v-if="userPermissions.indexOf('pa_kpi_target__save') !== -1"
                               v-model="row[column.property]"
                             @change="editVal(row,column.property)" :is-input-number="true"
              >
                          <span slot="content">
                           {{ row[column.property] }}
                            <i class="el-icon-edit default-edit-icon" v-if="showEditIcon"></i>
                          </span>
              </editable-cell>
                <span v-else>
                        {{ row[column.property] }}
                </span>
              </template>

            </el-table-column>
            <el-table-column
                prop="lately_quarter_income"
                label="上季度的月均营收"
                align="center"
                width="200">

              <template slot-scope="{row,column}">

                <editable-cell :show-input="false"
                             v-model="row[column.property]"
                               v-if="userPermissions.indexOf('pa_kpi_target__save') !== -1"
                             @change="editVal(row,column.property)" :is-input-number="true"
              >
                <span slot="content">
                  {{ valFormat(row[column.property]) }}
                  <i class="el-icon-edit default-edit-icon" v-if="showEditIcon"></i> </span>
              </editable-cell>
                <span v-else>
                        {{ valFormat(row[column.property]) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
                prop="real_income"
                label=""
                align="center"
                width="150">
              <template slot="header">
                系数8%
                <el-popover
                    placement="top-start"
                    width="200"
                    trigger="hover"
                    content="系数8%=上季度的月均营收*8%">
                  <i slot="reference" style="font-size: 12px;cursor: pointer;color: rgb(255, 49, 118)" class="el-icon-info"></i>
                </el-popover>
              </template>
              <template slot-scope="{row}">
                {{ Number(row.real_income) }}
              </template>
            </el-table-column>
            <el-table-column
                prop="target"
                label="季度目标"
                align="center"
                width="150">
              <template slot-scope="{row,column}">

                <editable-cell :show-input="false"
                               v-if="userPermissions.indexOf('pa_kpi_target__save') !== -1"
                               v-model="row[column.property]"
                             @change="editVal(row,column.property)" :is-input-number="true"
              >
                <span slot="content">  {{ valFormat(row[column.property]) }}
                  <i class="el-icon-edit default-edit-icon" v-if="showEditIcon"></i> </span>
              </editable-cell>
                <span v-else>
    {{ valFormat(row[column.property]) }}
                </span>
              </template>
            </el-table-column>
            <!--            <el-table-column-->
            <!--                prop="target"-->
            <!--                :label="`${quarterlyMonths[0]}月目标`"-->
            <!--                align="center"-->
            <!--                width="150">-->
            <!--              <editable-cell :show-input="false" slot-scope="{row,column}"-->
            <!--                             v-model="target_first"-->
            <!--                             @change="editVal(row,column.property)" :is-input-number="true"-->
            <!--              >-->
            <!--                          <span slot="content">{{ row['target_first'] }}-->
            <!--                            <i class="el-icon-edit default-edit-icon" v-if="showEditIcon"></i>-->
            <!--                          </span>-->
            <!--              </editable-cell>-->
            <!--            </el-table-column>-->
            <el-table-column v-for="(month,index) in quarterlyMonths" :key="index" :label="`${month}月目标`"
                             :prop="formatTarget(index)" align="center">
              <template slot-scope="{row,column}">
                <editable-cell :show-input="false"
                             v-model="target_number"
                               v-if="userPermissions.indexOf('pa_kpi_target__save') !== -1"
                             @change="editVal(row,column.property)" :is-input-number="true"
              >
                                      <span slot="content">

            {{ valFormat(row[formatTarget(index)]) }}  <i class="el-icon-edit default-edit-icon" v-if="showEditIcon"></i>
                                      </span>
              </editable-cell>
                <span v-else>
                   {{ valFormat(row[formatTarget(index)]) }}
              </span>
              </template>
            </el-table-column>

          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell'
import {numberFormat} from '@/utils/utils'
import {mapGetters} from 'vuex'
import {debounce} from "@/utils/debounce";

export default {
  name: 'BonusTable',
  components: {
    EditableCell
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType']),
    loadingText() {
      return `奖金明细数据加载中...`
    },
  },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {
          id: null
        }
      }
    },
  },
  watch: {
    searchCondition: {
      deep: true,
      handler() {
        // console.log('val',val)
        this.debouncedSearch()
      }
    },
  },
  created() {
    this.debouncedSearch = debounce(this.initList, 500)
  },
  data() {
    return {
      loadingStatus: false,
      showEditIcon: true,
      dataList: [],
      mergeFields: ['group_name', 'target', 'target_first', 'target_second', 'target_third'],//合并的字段
      quarterlyMonths: [],
      target_number: 0,
      debouncedSearch: null

    }
  },
  methods: {
    // 表格合并行
    // eslint-disable-next-line
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      let len = this.dataList.length;
      const fields = this.mergeFields
      if (fields.indexOf(column.property) > -1) {
        if (rowIndex % len === 0) {
          return {
            rowspan: len,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },

    getSummaries(param) {
      const {columns, data} = param
      let sums = []
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '合计'
          return
        }
        if (['lately_quarter_income', 'bef_two_quarter_profit','real_income'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }
        }
      })
      sums = sums.map(((i) => {
        if (Number(i) && Number(i) !== 0) {
          return `￥${this.valFormat(i)}`
        } else return i
      }))
      return sums
    },
    valFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    formatTarget(index) {
      return index === 0 ? 'target_first' : index === 1 ? 'target_second' : 'target_third'
    },
    editVal(row, colName) {
      if (['target_first', 'target_second', 'target_third'].includes(colName)) {
        row[colName] = this.target_number
        this.target_number = 0
      }
      this.$emit('editVal', colName,row)// 通知父组件 修改后的表格数据
    },
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }
      if (this.searchCondition.group_name) {
        condition.group_name = this.searchCondition.group_name
      }

      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      if (this.searchCondition.type) {
        condition.type = this.searchCondition.type
      }
      if (this.searchCondition.quarter) {
        condition.quarter = this.searchCondition.quarter
        if (this.searchCondition.months && this.searchCondition.months.length > 0) {
          condition.months = this.searchCondition.months
        }
      }

      return condition
    },
    async initList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      this.quarterlyMonths = searchCondition.months
      let {list} = await this.$api.getSetTargetList(searchCondition)
      this.dataList = list
      this.loadingStatus = false
    },
    editBonus(row) {
      this.$emit('editBonus', row)
    },
    //单元格样式调整
    // eslint-disable-next-line
    cellClassName({row, column, rowIndex, columnIndex}) {
      //判定是否标记隐藏：
      if (column.property === 'name' && row.is_show === 'N') {
        return 'cell-uncheck'
      }
      return ''
    },
    calcBonusClass(val) {
      if (val > 0) {
        return 'bonus-gt0'
      } else
        return ''
    },
    moneyFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    async clickRefresh(row) {
      this.$confirm(
          `确定重置${row.nickname}前两个季度月均利益和上季度月均营收？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
        let id = await this.$api.refreshSetTargetList({id: row.id})
        if (!id) {
          this.$message.error('重置失败')
        } else {
          this.$message.success('重置成功')
          await this.initList()
        }
      })
    }
  },
}
</script>

<style scoped>
.bill_no {
  color: #fff;
  opacity: 0.5;
  margin-right: 10px;
}

</style>
