<template>
  <div>
    <div class="performance-objectives">
      <el-row class="table-search">
    <el-form :model="searchCondition" :inline="true" size="small">
      <el-form-item>
        <el-date-picker
            v-model="searchCondition.year"
            type="year"
            format="yyyy"
            value-format="yyyy"
            @change="changeOption"
            placeholder="选择年度" style="width: 140px">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-popover
            placement="right"
            width="500"
            trigger="click"
        >
          <el-button size="small" type="default" slot="reference">
            选择季度<span v-if="searchCondition.quarter">: 第 {{ searchCondition.quarter }} 季度</span>
          </el-button>
          <el-row>
            <el-col :span="24">
              <el-radio-group @change="changeOption" v-model="searchCondition.quarter">
                <el-radio-button v-for="(quarter) in quarters" :label="quarter.value" :key="quarter.label">
                  {{ quarter.label }}
                </el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
        </el-popover>
      </el-form-item>
      <el-form-item>
        <DeptSelectTree :onlyDept='true' :searchCondition='searchCondition' ref='deptTree' style='width: 360px'
              :isType="'target'"          :isCheckStrictly="false" @handleSelect='handleChangeDept'></DeptSelectTree>
        <!--        <DeptCascaderScope :type="`performance_data`" ref="refDeptCascaderScope" :show-first-group="true"-->
        <!--                           :isDept="false" @selectedDept="selectDept" @firstGroup="currentGroup"/>-->
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click='handleSelect' icon='el-icon-search'>查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button icon='el-icon-setting'
                   v-has-authority="'pa_kpi_kol'" @click='showKolSetting'>
          红人管理
        </el-button>
      </el-form-item>
      <el-form-item>
        <LogPanel :infoIds="kpi_dept_id" buttonSize="small" ref='refLogPanel'
                  v-if="kpi_dept_id!==null" module-name='pa_kpi'></LogPanel>
      </el-form-item>
      <el-form-item style="float: right">
        <el-button style="float: right" type='warning' icon='el-icon-refresh'
                   v-has-authority="'pa_kpi_dept'" @click='showPaDeptInit'>项目部门管理
        </el-button>
      </el-form-item>
    </el-form>
      </el-row>
    </div>
    <!--    列表-->
    <BonusItemsTable type="2" ref="BonusTable" @editVal="editVal" :searchCondition="searchCondition"></BonusItemsTable>
    <!--    指定组操作-->
    <el-row style="margin-top: 10px">
      <el-button size="medium" v-has-authority="'pa_kpi_target__release'" style="float: right" type="primary" @click="handleSubmit">发布绩效目标</el-button>
    </el-row>
    <PaKolSetting :type="2" ref='refPaKolSetting'></PaKolSetting>
    <PaDeptInit ref='refPaDeptInit'/>
    <el-row :gutter="20" style="margin-top: 20px">
      <el-col>
        <span class="title">
          <i class="el-icon-info" style="font-size: 14px;color: #ff3176"></i>
          备注
        </span>
      </el-col>
      <el-col :span="6" style="margin-top: 10px">
        <table class="table">
          <thead>
          <tr>
            <th>目标达成率</th>
            <th>奖金</th>
          </tr>
          </thead>
          <tbody>
          <tr style="border: 1px solid red">
            <td>85%-105%</td>
            <td>{{ valFormat(30000) }}</td>
          </tr>
          <tr>
            <td>105%-120%</td>
            <td>{{ valFormat(60000) }}</td>

          </tr>
          <tr>
            <td>120%-135%</td>
            <td>{{ valFormat(90000) }}</td>
          </tr>
          <tr>
            <td>135%-150%</td>
            <td>{{ valFormat(120000) }}</td>
          </tr>
          <tr>
            <td>150%+</td>
            <td>{{ valFormat(125000) }}</td>
          </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import BonusSearch from './BonusSearch'
// import BonusTable from './BonusTable'
// import BonusHandle from './BonusHandle'
import BonusEdit from "@/pages/performance/bonus/BonusEdit";
import BonusList from "@/pages/performance/bonus/BonusList";
import {mapGetters} from "vuex";
import PaKolSetting from "@/pages/mcn_performance_data/component/PaKolSetting.vue";
import PaDeptInit from "@/pages/mcn_performance_data/component/PaDeptInit.vue";
import BonusItemsTable from './component/BonusItemsTable.vue'
import BonusTable from "@/pages/mcn_performance_data/component/BonusTable.vue";
import DeptSelectTree from './component/DeptSelectTree.vue'
import {numberFormat} from "@/utils/utils";
import LogPanel from "@/components/logs/LogPanel.vue";

export default {
  name: "BonusIndex",
  components: {
    LogPanel,
    DeptSelectTree,
    BonusTable,
    PaDeptInit,
    PaKolSetting,
    BonusList,
    BonusEdit,
    BonusItemsTable
    // BonusSearch, BonusTable, BonusHandle
  },
  data() {
    return {
      searchCondition: {year: null, group_id: null, group_name: '', months: [], quarter: 1, type: 2},
      dialogVisible: false,
      currentId: null,
      quarters: [
        {label: '第1季度', value: 1},
        {label: '第2季度', value: 2},
        {label: '第3季度', value: 3},
        {label: '第4季度', value: 4},
      ],
      kpi_dept_id: null
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  methods: {
    changeQuarter() {
      //计算季度对应的月份
      let quarter = this.searchCondition.quarter
      this.searchCondition.months = [quarter * 3 - 2, quarter * 3 - 1, quarter * 3];//月份的集合
    },
    handleChangeDept(obj) {
      this.searchCondition.group_id = obj.dept_id || null
      this.searchCondition.dept_name = obj.dept_name || ''
      this.kpi_dept_id = obj.kpi_dept_id || ''
    },
    changeOption() {
      if (this.$refs['deptTree'])
        this.$refs['deptTree'].show(this.searchCondition)
    },
    // async selectDept(valArr, labelArr) {
    //   let group_id = null;
    //   let group_name = null;
    //   if (valArr && valArr.length >= 1) {
    //     let len = valArr.length;
    //     group_id = valArr[len - 1];
    //     group_name = labelArr[len - 1];
    //   }
    //   await this.setGroupInfo(group_id, group_name)
    // },
    showKolSetting() {
      this.$refs['refPaKolSetting'].show(this.searchCondition)
    },
    // async currentGroup(group) {
    //   if (group && group.dpt_id) {
    //     await this.setGroupInfo(group.dpt_id, group.name)
    //   }
    // },
    // async setGroupInfo(group_id, group_name) {
    //   // this.$refs['refDeptCascaderScope'].initVal(group_id)
    //   this.searchCondition.group_id = group_id;
    //   this.searchCondition.group_name = group_name;
    //   this.changeQuarter()
    // },
    calcPermission() {
      return this.userPermissions.indexOf('bonus_save') > -1 || this.userPermissions.indexOf('bonus_check_1') > -1 || this.userPermissions.indexOf('bonus_check_2') > -1
    },
    showPaDeptInit() {
      this.$refs['refPaDeptInit'].show(this.searchCondition)
    },
    async editVal(colName, val) {
      console.log(val)
      if (['lately_quarter_income', 'bef_two_quarter_profit'].includes(colName)) {
        let update = {
          ...this.searchCondition, ...{
            [colName]: val[colName],
            artist_id: val['artist_id'],
            nickname: val['nickname'],
            group_id: val['group_id'],
            group_name: val['group_name'],
            dept_name: val['dept_name'],
            id: val['id']
          }
        }
        let data = await this.$api.EditTargetData(update)
        if (!data) {
          this.$message.error('修改失败')
        }
      } else if (['target', 'target_first', 'target_second', 'target_third'].includes(colName)) {

        let teamData =
            {
              target: val['target'],
              dept_id: val['group_id'],
              group_name: val['group_name'],
              target_first: val['target_first'],
              target_second: val['target_second'],
              target_third: val['target_third'],
              date_value: this.searchCondition.quarter,
              year: this.searchCondition['year'],
              type: this.searchCondition.type
            }
        //项目组目标
        let id = await this.$api.saveTargetData(teamData)
        if (!id) {
          this.$message.error('修改失败')
        }
      }
      await this.handleSelect()
    },
    async handleSelect() {
      await this.$refs['BonusTable'].initList()

    },
    async handleSubmit() {
      let data = {
        year: this.searchCondition.year,
        date_value: this.searchCondition.quarter,
        dept_id: this.searchCondition.group_id,
        type: 2,
        quarter: this.searchCondition.quarter,
      }
      this.$confirm(
          `确定发布第${this.searchCondition.quarter}季度绩效吗？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
        let id = await this.$api.releaseTargetData(data)
        if (!id) {
          this.$message.error('修改失败')
        } else {
          this.$message.success('发布成功')
        }
      })
    },
    valFormat(value) {
      return numberFormat(value, 0, '.', ',', 'round')
    },

  },
  mounted() {
    this.searchCondition.year = (new Date()).getFullYear() + ''
    // 获取当前季度：
    this.searchCondition.quarter = this.$utils.getCurrentQuarter()
    this.changeQuarter()

    this.$refs['deptTree'].show(this.searchCondition)
  },
}
</script>

<style lang="scss" scoped>
.title {
  margin-top: 5px;
  margin-left: 10px;
  font-size: 12px;
}

.tips {
  margin-top: 10px;
}

//table, th, td {
//  border: 1px solid black;
//  border-collapse: collapse; /* 移除单元格间的间隔 */
//}

table {
  border: 1px solid black;
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  text-align: center;
  border: 1px solid #ddd;
}

/* 隔列换背景色 */
tr:nth-child(even) td:nth-child(odd),
tr:nth-child(odd) td:nth-child(even) {
  background-color: #f2f2f2;
}
</style>